
import { defineComponent, reactive, onBeforeMount } from 'vue';
import PartnerEngagementSideBar from '@/modules/partner-engagement/routes/PartnerEngagementSideBar';
import SideBarNav from '@/components/sidebar/SideBarNav.vue';
import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';

export default defineComponent({
    name: 'planning',
    components: {
        SideBarNav,
    },
    props: {},
    setup() {
        const sideBar = new PartnerEngagementSideBar();
        const menuItems: SideBarMenuItem[] = reactive([]);

        onBeforeMount(async () => {
            await sideBar.updateMenuItems();
            menuItems.push(...sideBar.menuItems);
        });

        return {
            menuItems,
        };
    },
});
