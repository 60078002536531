import SideBarMenuItem from '@/components/sidebar/domain/SideBarMenuItem';
import PartnerEngagementRouteTypes from '@/modules/partner-engagement/routes/types';
import ISideBar from '@/interfaces/ISideBar';
import Permissions from '@/services/permissions/Permissions';
import SystemApiService from '@/services/api/SystemApiService';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';

export default class PartnerEngagementSideBar implements ISideBar {
    private systemApiService: SystemApiService;
    public menuItems: SideBarMenuItem[] = [];

    constructor() {
        this.systemApiService = new SystemApiService();
    }

    public updateMenuItems() {
        this.menuItems = [
            {
                id: 1,
                title: getTranslation('core.common.general').toUpperCase(),
                type: SideBarMenuItem.TYPE.HEADER,
                isSectionHeader: true,
                show: true,
            },
            {
                id: 130,
                title: getTitleCaseTranslation('core.common.dashboard'),
                icon: 'chart-line',
                routeName: PartnerEngagementRouteTypes.DASHBOARD,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.PARTNER_ENGAGEMENT.canAccessDashboard(),
            },
            {
                id: 2,
                title: getTitleCaseTranslation('core.domain.transactions'),
                icon: 'truck',
                routeName: PartnerEngagementRouteTypes.TRANSACTION.LISTALL,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.PARTNER_ENGAGEMENT.canAddShipment() 
                    || Permissions.PARTNER_ENGAGEMENT.canOrderPackaging() 
                    || Permissions.PARTNER_ENGAGEMENT.canAdjustCount()
                    || Permissions.PARTNER_ENGAGEMENT.canConfirmDisputeInboundReceipts(),
            },
            {
                id: 3,
                title: getTitleCaseTranslation('core.domain.inventory'),
                icon: 'box',
                routeName: PartnerEngagementRouteTypes.INVENTORY.LIST,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.PARTNER_ENGAGEMENT.canViewInventory(),
            },
            {
                id: 4,
                title: getTitleCaseTranslation('core.domain.cycleCount'),
                icon: 'clipboard',
                routeName: PartnerEngagementRouteTypes.CYCLE_COUNT_REPORT.REPORT,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.PARTNER_ENGAGEMENT.canRecordCount(),
            },
            {
                id: 120,
                title: getTranslation('core.domain.productionPart'),
                icon: 'box',
                routeName: PartnerEngagementRouteTypes.PRODUCTION_PART.LIST,
                type: SideBarMenuItem.TYPE.BUTTON,
                show: Permissions.PARTNER_ENGAGEMENT.canEditProductionPartData(),
            },
        ];
    }
}
