import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_side_bar_nav = _resolveComponent("side-bar-nav")!

  return (_openBlock(), _createBlock(_component_side_bar_nav, { "menu-items": _ctx.menuItems }, {
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1
  }, 8, ["menu-items"]))
}